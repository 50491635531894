import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import Link from '@mui/material/Link'

const cardContent = [
  {
    title: 'Recommend Movies (Powered by GPT-3.5)',
    content: '',
    link: 'movie/recommend',
  },
  {
    title: 'Trending Movies (TMDB)',
    link: 'movie/trending',
  },
]

const SlidableCard = ({ activeIndex, onNext, onPrev }) => {
  const card = cardContent[activeIndex]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <IconButton
        onClick={onPrev}
        color="primary"
        style={{
          marginRight: '1rem',
        }}
        size="large"
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            {card.title}
          </Typography>
          <Button
            component={Link}
            href={card.link}
            target="_blank"
            rel="noopener"
            color="inherit"
            variant="contained"
            className="mt-1"
          >
            Go to project
          </Button>
        </CardContent>
      </Card>
      <IconButton
        onClick={onNext}
        // variant="contained"
        color="primary"
        style={{
          marginLeft: '1rem',
        }}
        size="large"
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  )
}
export const Projects = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0)

  const handleNextCard = () => {
    setActiveCardIndex((prevIndex) => (prevIndex + 1) % cardContent.length)
  }

  const handlePrevCard = () => {
    setActiveCardIndex((prevIndex) =>
      prevIndex === 0 ? cardContent.length - 1 : prevIndex - 1,
    )
  }

  return (
    <div>
      <h1 style={{ color: 'black' }}>Projects</h1>
      <SlidableCard
        activeIndex={activeCardIndex}
        onNext={handleNextCard}
        onPrev={handlePrevCard}
      />
    </div>
  )
}
