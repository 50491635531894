import React, { useState } from 'react'
import {
  Button,
  Modal,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material'

export const MovieSearchModal = ({ fetchData }) => {
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])

  // Simulated search function
  const handleSearch = async () => {
    // Implement your search logic here
    // Simulated search results, replace with your actual search results
    const response = await fetch(
      `https://20ghijl821.execute-api.us-east-1.amazonaws.com/dev/movies/search?query=${searchTerm}`,
    )
    const result = await response.json()
    setSearchResults(JSON.parse(result.body))
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Search Movies
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            border: '2px solid #000',
            padding: '2rem',
            maxHeight: '80%', // Adjust the maxHeight as needed
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              marginTop: '1rem',
            }}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              style={{ marginTop: 10, marginRight: '1.5rem' }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ marginTop: 10 }}
            >
              Close
            </Button>
          </div>
          {searchResults.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                overflowY: 'auto',
                maxHeight: '80%',
              }}
            >
              <h3>Search Results:</h3>
              {searchResults.map((result, index) => (
                <div key={index} style={{ display: 'flex' }}>
                  <FormControlLabel
                    key={index}
                    control={<Checkbox />}
                    label={
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          marginTop: '1rem',
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: 50,
                            width: 50,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                            marginLeft: '1rem',
                            marginRight: '1rem',
                          }}
                          alt="The house from the offer."
                          src={result.imageUrl}
                        />
                        <p>{result.original_title}</p>
                      </div>
                    }
                  />
                </div>
              ))}
            </div>
          )}
          {/* <Button
            variant="contained"
            onClick={handleClose}
            style={{ marginTop: 10 }}
          >
            Close
          </Button> */}
        </div>
      </Modal>
    </div>
  )
}
