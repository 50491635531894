// Profile.js
import React from 'react'
import { Card, CardHeader, Avatar } from '@mui/material'
import ProfilePicture from '../assets/profile.jpeg'
import ProfileLinks from './ProfileLinks'
const ProfileCard = () => {
  return (
    <Card
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: 'none',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            src={ProfilePicture}
            alt={'Justin Hamin Lee'}
            sx={{ width: '40vh', height: '40vh' }}
          />
        }
      />
      <h3 style={{ color: 'white' }}>Hamins world</h3>
      <ProfileLinks />
    </Card>
  )
}

export default ProfileCard
