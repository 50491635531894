import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
} from '@mui/material'

const columns = [
  { id: 'rowNumber', label: '#', minWidth: 50 },
  { id: 'imageUrl', label: '', minWidth: 200 },
  { id: 'original_title', label: 'Name', minWidth: 170 },
  { id: 'original_language', label: 'Language', minWidth: 100 },
  { id: 'vote_average', label: 'Vote Avg.', minWidth: 100 },
  { id: 'popularity', label: 'Popularity', minWidth: 100 },
  // Add more columns as needed
]

export const MovieTable = ({ movies }) => {
  // State for pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // Event handler for page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Event handler for rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="left">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {movies
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  {columns.slice(1).map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.id === 'imageUrl' ? (
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 200,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={row[column.id]}
                        />
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={movies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
