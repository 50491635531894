// App.js
import React from 'react'
import VideoBackground from './components/VideoBackground'
import './App.css'
import ProfileCard from './components/ProfileCard'
import TrendingMovies from './components/MovieRecommender/TrendingMovies'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MovieRecommenderAI from './components/MovieRecommender/MovieRecommenderAI'
import { Projects } from './components/Projects'
// import { AppBar, Tabs, Tab } from '@mui/material'

// const Navbar = () => {
//   return (
//     <AppBar position="static">
//       <Tabs>
//         <Tab label="Home" component={Link} to="/" />
//         <Tab label="Movie Recommend" component={Link} to="/movie" />
//         <Tab label="Contact" component={Link} to="/contact" />
//       </Tabs>
//     </AppBar>
//   )
// }

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          {/* <h1>Wassup</hw1> */}
          {/* <Navbar /> */}
          <Routes>
            <Route path="/" element={<ProfileCard />} />
            <Route path="/movie/trending" element={<TrendingMovies />} />
            <Route path="/movie/recommend" element={<MovieRecommenderAI />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
          <VideoBackground />

          {/* <ProfileCard /> */}
          {/* <Route path="/contact" component={Contact} /> */}
          {/* <MovieRecommenderAI /> */}
        </header>
      </div>
    </Router>
  )
}

export default App
