import React, { useEffect, useState } from 'react'
import { Card, Select, MenuItem, InputLabel } from '@mui/material'
import ProfileLinks from '../ProfileLinks'
// import { getMovieRecommenderApiPath } from '../constant'
import { MovieTable } from './MovieTable'
import { MovieSearchModal } from './MovieSearchModal'

const TrendingMovies = () => {
  const [data, setData] = useState({
    movieRecommendation: '',
    isLoading: true,
    topN: 10,
    movies: [],
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://20ghijl821.execute-api.us-east-1.amazonaws.com/dev/movies/top${data.topN}`,
        )
        const result = await response.json()

        setData({
          ...data,
          movies: result.statusCode === 200 ? JSON.parse(result.body) : [],
          isLoading: false,
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [data.topN])

  const handleNumberChange = (event) => {
    // Validate and handle the number input here if needed
    setData({ ...data, topN: event.target.value })
  }

  if (data.isLoading) return <div>loading...</div>

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: 'none',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1>Trending Movies</h1>
      <InputLabel id="number-label">Select number of movies</InputLabel>

      <Select
        labelId="number-label"
        id="number-select"
        value={data.topN}
        onChange={handleNumberChange}
        label="Select a Number"
        style={{
          backgroundColor: 'white',
          marginBottom: '1rem',
        }}
      >
        {/* Generate a list of number options */}
        {Array.from({ length: 20 }, (_, index) => (
          <MenuItem key={index + 1} value={index + 1}>
            {index + 1}
          </MenuItem>
        ))}
      </Select>
      <MovieTable movies={data.movies} />
      {/* <h6 style={{ color: 'black' }}>{JSON.stringify(data.topNMovies)}</h6> */}
      <ProfileLinks />
    </Card>
  )
}

export default TrendingMovies
