import React from 'react'
// import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WorkIcon from '@mui/icons-material/Work'

const ProfileLinks = () => {
  const githubUrl = 'https://github.com/hamin-lee'
  const linkedinUrl = 'https://www.linkedin.com/in/hamin-lee'
  const projectPath = '/projects'

  const flexCol = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }
  const iconSize = {
    width: '7vw',
    height: '7vh',
  }

  return (
    <div style={flexCol}>
      {/* GitHub Button */}
      <IconButton
        component={Link}
        href={githubUrl}
        target="_blank"
        rel="noopener"
        color="inherit"
      >
        <GitHubIcon style={iconSize} />
      </IconButton>

      {/* LinkedIn Button */}
      <IconButton
        component={Link}
        href={linkedinUrl}
        target="_blank"
        rel="noopener"
        color="inherit"
      >
        <LinkedInIcon style={iconSize} />
      </IconButton>

      <IconButton
        component={Link}
        href={projectPath}
        target="_blank"
        rel="noopener"
        color="inherit"
      >
        <WorkIcon style={iconSize} />
      </IconButton>

      {/* Project Path Button */}
      {/* <Button component={Link} href={projectPath} color="primary">
          Project
        </Button> */}
    </div>
  )
}

export default ProfileLinks
