import React, { useEffect, useState } from 'react'
import { Card, Select, MenuItem, InputLabel } from '@mui/material'
import ProfileLinks from '../ProfileLinks'
// import { getMovieRecommenderApiPath } from '../constant'
import { MovieTable } from './MovieTable'
import { MovieSearchModal } from './MovieSearchModal'

const MovieRecommenderAI = () => {
  const [data, setData] = useState({
    movieRecommendation: '',
    topNMovies: ['spider man'],
    recommendation: '',
    isLoading: true,
  })
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://20ghijl821.execute-api.us-east-1.amazonaws.com/dev/recommend/movie?movies=${data.topNMovies.join(
          ',',
        )}`,
      )
      const result = await response.json()
      console.log(result)
      setData({
        ...data,
        recommendation: result.statusCode === 200 ? result.body : [],
        isLoading: false,
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    // fetchData()
  }, [])

  const handleNumberChange = (event) => {
    // Validate and handle the number input here if needed
    setData({ ...data, topN: event.target.value })
  }

  //   if (data.isLoading) return <div>loading...</div>

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: 'none',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h3>Recommend Movie (Powered by GPT-3.5)</h3>
      <MovieSearchModal fetchData={fetchData} />

      <h5>Selected movies:</h5>
      {data.topNMovies.map((cur, index) => (
        <p key={index}>{cur}</p>
      ))}

      <p>{data.recommendation}</p>

      <ProfileLinks />
    </Card>
  )
}

export default MovieRecommenderAI
