import React from 'react'
import ReactPlayer from 'react-player'
import MyVideo from '../assets/video.mp4'

const VideoBackground = () => {
  return (
    <div className="video-background">
      <ReactPlayer
        url={MyVideo} // replace with your actual video URL
        playing
        playsinline
        loop
        muted
        width="auto"
        height="100vh"
      />
    </div>
  )
}

export default VideoBackground
